<template>
  <div>
    <page-toolbar title="Modify Data Access"></page-toolbar>
    <v-container>
      <page-title title="Modify Data Access">
        <template slot="action">
          <v-btn @click="goBack()" class="mx-1">Back</v-btn>
        </template>
      </page-title>
      <v-card :loading="loading">
        <v-toolbar dense elevation="0">
          <v-toolbar-title>Modify Data Access</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="showAddDialog()" title="Add">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="3">
              <v-select label="Access Type" outlined dense hide-details v-model="formData.accessType" :items="accessTypes" @change="getData(true)" class="mb-4"></v-select>
              <v-autocomplete label="Area Code" outlined dense hide-details v-model="formData.areaCode" :disabled="disabledAreaCode" placeholder="Area Code" class="mb-4" :items="list_area" item-text="area_name" item-value="area_code" :loading="loading_get_area" @change="getData(true)"></v-autocomplete>
              <v-autocomplete label="Department" outlined dense hide-details v-model="formData.departmentId" :disabled="disabledDepartmentId" placeholder="Department" class="mb-4" :items="list_department" item-text="dept_name" item-value="id" :loading="loading_get_department" @change="getData(true)"></v-autocomplete>
              <!-- <v-autocomplete label="User" outlined dense hide-details placeholder="User" class="mb-4" :items="users" item-text="username" item-value="id" v-model="user_id" :loading="loadingUsers" @change="getUserPerms()"></v-autocomplete> -->

              <v-btn block @click="getData(true)" flat outlined color="primary" :loading="datatable.loading" class="mb-2"> <v-icon small>mdi-magnify</v-icon> Search</v-btn>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="9">
              <v-row>
                <v-col>
                  <v-autocomplete label="Users" outlined dense clearable hide-details v-model="selectedUser" placeholder="Users" class="mb-4" :items="list_user" item-text="username" item-value="username" :loading="loading_get_user || loading_add" :disabled="loading_get_user || loading_add">
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title>User Not Found!</v-list-item-title>
                      </v-list-item>
                    </template>
                    <template v-slot:selection="{ attr, on, item, selected }">
                      <v-chip v-bind="attr" :input-value="selected" color="blue-grey" class="white--text" v-on="on">
                        <span v-text="item.username"></span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-list-item-avatar color="indigo" class="text-h5 font-weight-light white--text">
                        {{ item.username.charAt(0) }}
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ item.username }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.fullname }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn :loading="loading_add" @click="addUser()">Add</v-btn>
                </v-col>
              </v-row>
              <v-card flat elevation="0" outlined>
                <v-toolbar dense elevation="0">
                  <div style="max-width: 100px">
                    <v-select :items="itemsPerPageOption" label="Show Items" dense v-model="datatable_options.itemsPerPage" style="max-width: 100px" hide-details single-line></v-select>
                  </div>

                  <v-spacer></v-spacer>

                  <v-text-field v-if="!isMobile" dense autofocus outlined rounded placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 300px" hide-details single-line></v-text-field>

                  <v-btn icon @click="getData(true)" title="Refresh">
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                </v-toolbar>

                <div class="px-2" v-if="isMobile">
                  <v-text-field class="mb-2" dense clearable autofocus outlined placeholder="Search" v-model="search" append-icon="mdi-magnify" style="max-width: 3000px" hide-details single-line></v-text-field>
                </div>

                <v-data-table :options.sync="datatable_options" :loading="datatable.loading" :loading-text="datatable.loadingText" :headers="datatable.columns" :items="datatable.data" :search="search" :hide-default-header="isMobile" :server-items-length="datatable.totalRows" :items-per-page="datatable_options.itemsPerPage" dense class="mb-3 my-0" hide-default-footer @page-count="pageCount = $event">
                  <template v-slot:[`item.action`]="{ item }" v-if="!isMobile">
                    <v-btn icon text x-small class="mx-1" color="primary" @click="deleteUser(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>

                  <v-alert slot="no-results" :value="true" color="error" icon="warning">Your search for "{{ search }}" found no results.</v-alert>

                </v-data-table>
                <div class="text-right pt-2 mx-auto" style="max-width: 600px">
                  <v-pagination v-model="datatable_options.page" :length="totalPage"></v-pagination>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'data-access',
  data() {
    return {

      accessTypes: ['area', 'department'],

      list_area: [],
      loading_get_area: false,
      list_department: [],
      loading_get_department: false,
      list_user: [],
      list_user_tmp: [],
      loading_get_user: false,
      search_user: "",


      list_user_da: [],
      list_user_da_tmp: [],
      loading_get_user_da: false,
      search_user_da: "",


      moduleId: null,
      formData: {
        accessType: 'area',
        areaCode: 'HO',
        departmentId: 0,
      },


      users: [],
      loadingUsers: false,
      user_id: null,

      dialog_detail: null,
      loading: null,
      search: "",
      datatable_options: {},
      itemsPerPageOption: [10, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "ID", value: "id", class: "text-no-wrap" },
          { text: "Username", value: "username", class: "text-no-wrap" },
          { text: "#", value: "action", name: "action", class: "text-no-wrap", searchable: false, sortable: false },
        ],
        data: [],
      },

      selectedItem: {},
      disabledAreaCode: false,
      disabledDepartmentId: false,

      loading_add: false,

      selectedUser: null,
    };
  },
  watch: {
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },

    formData: {
      handler() {
        if (this.formData.accessType == 'department') {
          this.disabledAreaCode = true;
        } else {
          this.disabledAreaCode = false;
        }
        if (this.formData.accessType == 'area') {
          this.disabledDepartmentId = true;
        } else {
          this.disabledDepartmentId = false;
        }
      },
      deep: true,
    },

    search_user: {
      handler() {
        this.getListUser();
      },
      deep: true,
    }

  },
  methods: {
    load() {
      this.getListArea();
      this.getListDepartment();
      this.getListUser();
      // this.getListDataAccessUser();
    },
    refreshData() {
      this.getData(true);
    },
    getData(refresh) {
      if (refresh) this.AxiosStorageRemove("POST", "dataaccess/data-users");

      const { sortBy, sortDesc, page, itemsPerPage } = this.datatable_options;

      this.datatable.loading = true;
      this.datatable.data = [];

      var formData = new FormData();
      formData.append("search", this.search);
      formData.append("sort_by", sortBy);
      formData.append("sort_desc", sortDesc);
      formData.append("page", page);
      formData.append("limit", itemsPerPage);

      formData.append("access_type", this.formData.accessType);

      if (this.formData.accessType == 'area') formData.append("area_code", this.formData.areaCode);
      if (this.formData.accessType == 'department') formData.append("department_id", this.formData.departmentId);

      this.$axios
        .post("dataaccess/data-users", formData)
        .then((res) => {
          var resData = res.data;
          if (resData.status == "success") {
            this.datatable.data = resData.data.results;
            this.datatable.totalRows = resData.data.paging.total_rows;
            this.totalPage = resData.data.paging.total_page;
          } else {
            this.showAlert(resData.status, resData.message);
          }
          this.datatable.loading = false;
        })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.datatable.loading = false;
        });
    },
    async deleteData(item) {

      const post = async () => {
        this.loading = true;

        const uri = 'module/delete-user-perm';
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios.post(uri, formData)
          .then((res) => {
            const resData = res.data;
            if (resData.status == 'success') {
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }

            this.loading = false;

            this.getData(true);
          })
          .catch((e) => {
            this.loading = false;

            this.getData(true);

            this.axiosErrorHandler(e);
          });
      }

      this.showConfirm('Confirm', 'Delete Permission?', post);


    },

    async getListArea() {
      this.loading_get_area = true;
      await this.$axios.get("dataaccess/list-area").then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.list_area = resData.data.results;
        }

        this.loading_get_area = false;
      })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading_get_area = false;
        });
    },

    async getListDepartment() {
      this.loading_get_department = true;
      await this.$axios.get("dataaccess/list-department").then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.list_department = resData.data.results;
        }

        this.loading_get_department = false;
      })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading_get_department = false;
        });
    },

    async getListUser() {
      this.loading_get_user = true;
      let config = {
        params: {
          search: this.search_user
        }
      }
      await this.$axios.get("dataaccess/list-user", config).then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.list_user = resData.data.results;
        }

        this.loading_get_user = false;
      })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading_get_user = false;
        });
    },

    async getListDataAccessUser() {
      this.loading_get_user_da = true;
      let config = {
        params: {
          search: this.search_user_da,
          access_type: this.access_type,
          area_code: this.area_code,
          department_id: this.department_id,
        }
      }
      await this.$axios.get("dataaccess/list-user-da", config).then((res) => {
        var resData = res.data;

        if (resData.status == "success") {
          this.list_user_da = resData.data.results;
        }

        this.loading_get_user_da = false;
      })
        .catch((error) => {
          this.axiosErrorHandler(error);
          this.loading_get_user_da = false;
        });
    },

    async addUser() {
      this.loading_add = true;

      const uri = 'dataaccess/add-user';
      const formData = new FormData();
      if (this.selectedUser != null) formData.append("username", this.selectedUser);
      if (this.formData.accessType != null) formData.append("access_type", this.formData.accessType);
      if (this.formData.areaCode != null) formData.append("area_code", this.formData.areaCode);
      if (this.formData.departmentId != null) formData.append("department_id", this.formData.departmentId);
      await this.$axios.post(uri, formData)
        .then((res) => {
          const resData = res.data;
          if (resData.status == 'success') {
            this.showAlert(resData.status, resData.message);
          } else {
            this.showAlert(resData.status, resData.message);
          }

          this.loading_add = false;

          this.getData(true);
        })
        .catch((e) => {
          this.loading_add = false;

          this.getData(true);

          this.axiosErrorHandler(e);
        });
    },

    async deleteUser(item) {

      const post = async () => {
        this.loading = true;

        const uri = 'dataaccess/delete-user';
        const formData = new FormData();
        formData.append("id", item.id);
        await this.$axios.post(uri, formData)
          .then((res) => {
            const resData = res.data;
            if (resData.status == 'success') {
              this.showAlert(resData.status, resData.message);
            } else {
              this.showAlert(resData.status, resData.message);
            }

            this.loading = false;

            this.getData(true);
          })
          .catch((e) => {
            this.loading = false;

            this.getData(true);

            this.axiosErrorHandler(e);
          });
      }

      this.showConfirm('Confirm', 'Delete User?', post);


    },
  },
  mounted() {
    this.load();
  }
}
</script>